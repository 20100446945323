import PropTypes from 'prop-types';
import React from 'react';

import styles from './producthunt-bar.module.css';

const ProductHuntBar = ({}) => (
  <div>
    <div className={styles.bar}>
      <a href="https://www.producthunt.com/posts/stocksolo" className={styles.link}>
        We are live on ProductHunt 😺​
      </a>
    </div>
    <div className="mb-10"/>
  </div>
);

ProductHuntBar.propTypes = {
  siteTitle: PropTypes.string,
};

ProductHuntBar.defaultProps = {
  siteTitle: ``,
};

export default ProductHuntBar;
