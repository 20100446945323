import React, {useState} from 'react';
import Modal from 'react-modal';

import Layout from '../components/layout';
import SEO from '../components/seo';

import logo from '../images/stock-solo-logo-white.svg';
import ogImage from '../images/og-3.jpg';
import ogImageTwitter from '../images/og-3.jpg';
import screen1 from '../images/screen-xd-1.png';
import demo from '../assets/demo-02.mp4';

import styles from './index.module.css';
import ProductHuntBar from '../components/index/producthunt-bar';
import ProductHuntBadge from '../components/index/producthunt-badge';

const IndexPage = () => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);

  const renderThanks = () => {
    return <div
      className="w-full text-center bg-yellow-200 p-5 lg:p-10 rounded-lg text-yellow-800">
      Please check your inbox and confirm your email. Thank you!
      {/*Thank you for signing up! You will hear from us soon.*/}
    </div>;
  };

  let btnClasses = 'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 ' +
    'px-4 mt-5 lg:mt-0 lg:ml-2 w-full rounded focus:outline-none ' +
    'focus:shadow-outline lg:flex-2 text-center rounded-full py-2 px-4';

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      maxWidth: '900px',
      transform: 'translate(-50%, -50%)',
    },
  };

  return (
    <Layout>
      <SEO title="StockSolo" ogImageUrl={ogImage} ogImageTwitterUrl={ogImageTwitter}/>
      {/*{<ProductHuntBar/>}*/}

      {/* Logo */}
      <div className="py-12">
        <img src={logo} alt={'StockSolo'} className={`${styles.logo} mx-auto`}/>
      </div>

      <div className="mx-auto">
        <h1
          className={'text-center leading-relaxed antialiased text-3xl'}>
          Beautiful <span
          className={'bg-red-600 p-1'}>free pictures</span> inside Adobe
          Photoshop, Illustrator, InDesign and XD

          {/*Search and use Unsplash <span className={'bg-red-600 p-1'}>stock photos</span> directly*/}
          {/*inside Adobe Photoshop, Illustrator and InDesign*/}
        </h1>

        <h3
          className={'px-10 text-gray-300 text-center leading-relaxed antialiased text-xl'}>
          Download more than <u>3.2 Million photos</u> from Unsplash, Pexels, Pixabay and Adobe Stock
          {/*Bring Unsplash inside Adobe Creative Cloud*/}
        </h3>
      </div>

      <div className={'text-center'}>
        <video className={'py-16 lg:w-3/4 md:w-full'}
          // onLoadedData="this.play();"
               autoplay={'autoplay'}
               playsinline loop muted controls>
          <source
            src={demo}
            type="video/mp4"/>
          {/*Your browser does not support the video tag or the file format of this*/}
          {/*video.*/}
        </video>
      </div>

      {/* Screenshot */}
      {/*<div className="py-10 mx-auto">*/}
      {/*  <img src={screenshot}*/}
      {/*       alt="StockSolo Screenshot"*/}
      {/*       className={styles.screenshot}/>*/}
      {/*</div>*/}

      <div className="md:flex text-blue-900">
        <div className={'flex-1 m-4'}>
          <div
              className="w-full text-center bg-gray-200 p-5 lg:p-10 rounded-lg">
            <div className={`mb-10 font-bold ${styles.boxTextContainer}`}>
              For Adobe Photoshop
            </div>
            <a href="https://adobe.ly/3k3rsKU" className={btnClasses}
               target={'_blank'}>
              Download
            </a>
          </div>
        </div>

        <div className={'flex-1 m-4'}>
          <div
              className="w-full text-center bg-gray-200 p-5 lg:p-10 rounded-lg">
            <div className={`mb-10 font-bold ${styles.boxTextContainer}`}>
              For Adobe Illustrator, InDesign
            </div>
            <a href="https://adobe.ly/2Q7xVHR" className={btnClasses}
               target={'_blank'}>
              Download
            </a>
          </div>
        </div>

        <div className={'flex-1 m-4'}>
          <div
              className="w-full text-center bg-gray-200 p-5 lg:p-10 rounded-lg">
            <div className={`mb-10 font-bold ${styles.boxTextContainer}`}>
              For Adobe XD
            </div>
            <a href={'http://bit.ly/2wbZTeU'} className={btnClasses}>
              Download
            </a>
          </div>
        </div>
      </div>

      {/*<div className={'mt-20'}>*/}
      {/*  <ProductHuntBadge/>*/}
      {/*</div>*/}

      {/*<div className={'mt-16 text-center'}>*/}
      {/*  <a href="/upgrade/">*/}
      {/*    Looking to upgrade the extension manually? Click here*/}
      {/*  </a>*/}
      {/*</div>*/}

      {/* Form */}
      {/*{isSubmitted ?*/}
      {/*  renderThanks() :*/}
      {/*  <Form isSubmitting={isSubmitting}*/}
      {/*        email={email}*/}
      {/*        setEmail={setEmail}*/}
      {/*        setSubmitted={setSubmitted}*/}
      {/*        setSubmitting={setSubmitting}/>}*/}

      <Modal
        style={customStyles}
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        contentLabel="Install StockSolo on Adobe XD"
      >
        <div className="text-gray-700 font-sans">
          You can find the plugin by <b>launching Adobe XD</b> and clicking <b>"Discover
          Plugins"</b> from the Plugins menu.
          <div className="mt-10">
            <img src={screen1} alt="Screenshot" className={'w-full'}/>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default IndexPage;
