// import PropTypes from 'prop-types';
import React from 'react';

const ProductHuntBadge = () => {
  return <a
    href="https://www.producthunt.com/posts/stocksolo?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-stocksolo"
    target="_blank">
    <img
      src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=205401&theme=light"
      alt="StockSolo - Beautiful free stock photos inside Adobe XD, PS, IL and ID | Product Hunt Embed"
      style={{
        width: '250px',
        height: '54px',
        margin: '0 auto',
      }}/>
  </a>;
};

ProductHuntBadge.propTypes = {
};

ProductHuntBadge.defaultProps = {
};

export default ProductHuntBadge;
